$enable-negative-margins: true;

@import 'styles/colors';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/_variables.scss';
@import 'styles/statusColors';

/* Common Styles */
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Open Sans', sans-serif;
}
#root > div {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .main {
    flex: 1 0;
    min-height: 100px;
  }
}
.bg-gray {
  background-color: $gray;
}
.bg-gray-light {
  background-color: $grayLight;
}
.container-xxxl {
  max-width: 1475px;
  margin-left: auto;
  margin-right: auto;
}
.font-sm {
  font-size: 0.875rem;
}
.font-xs {
  font-size: 0.75rem;
}
.font-inherit {
  font-size: inherit;
}
.fw-500 {
  font-weight: 500;
}
.text-justify {
  text-align: justify;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-disabled {
  color: $disabled;
}
.line-1 {
  line-height: 1;
}
.rounded-end-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown-toggle.btn-outline-secondary {
  text-align: inherit;
  padding-right: 1.5rem;
  position: relative;
  border-color: #ced4da;
  color: #495057;
}
.dropdown-toggle.btn-outline-secondary::after {
  position: absolute;
  right: 0.55rem;
  top: 50%;
  margin-top: -0.15em;
}
.dropdown-toggle.btn-outline-secondary:hover {
  background: none;
  color: #495057;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  background: none;
  border-color: #f87b9d;
  color: #495057;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 13, 73, 0.25);
  border-color: #f87b9d;
}
.btn-link {
  text-decoration: none;
}
.btn-input {
  border-color: $input-border-color;
  &:hover {
    border-color: #111;
  }
}

input:read-only:focus {
  outline: none;
}

.card-title.text-primary {
  border-left: 0.25rem solid;
  border-radius: 0;
}
.inactive-header {
  background: #f0f0f0;
}
.cursor-pointer {
  cursor: pointer;
}
.bg-inactive {
  background: #ffe4eb;
}
.v-middle {
  vertical-align: middle
}
.scroll-margin {
  scroll-margin: 6rem;
}

/*Form */
.form-row {
  display: flex;
  margin-left: -.25rem;
  margin-right: -.25rem;
  & > .col,
  & > [class*="col-"] {
    padding-left: .25rem;
    padding-right: .25rem;
  }
}
.form-select {
  height: 3rem;
}
.floating-group {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  .form-control {
    text-align: inherit;
  }
  &.hasPrepend .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.hasAppend .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-control,
  .select-with-flag {
    height: 3rem;
    transition: border-color 200ms cubic-bezier(0, 0, 1, 0.5) 100ms,
      color 200ms cubic-bezier(0, 0, 1, 0.5) 100ms,
      box-shadow 200ms cubic-bezier(0, 0, 1, 0.5) 100ms;
  }
  .form-control:-webkit-autofill,
  .form-control:-webkit-autofill:hover,
  .form-control:-webkit-autofill:focus,
  .form-control:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .select-with-flag {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .form-control::-webkit-input-placeholder {
    color: transparent;
  }
  .form-control:-ms-input-placeholder {
    color: transparent;
  }
  .form-control::-ms-input-placeholder {
    color: transparent;
  }
  .form-control::-moz-placeholder {
    color: transparent;
  }
  .form-control::placeholder {
    color: transparent;
  }
  .form-label {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    margin: 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #87939e;
    padding: 0 0.25rem;
    line-height: 1rem;
    transform: translate(0.5rem, 1rem) scale(1);
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background: #fff;
    width: fit-content;
  }
  &.floating-group-select {
    .form-label {
      right: 2.25rem;
      pointer-events: none;
    }
    &.active .form-label{
      right: auto;
    }
  }
  .form-control:not(:placeholder-shown) ~ .form-label,
  .form-control:focus ~ .form-label,
  .select-with-flag .form-label,
  &.active .form-label {
    transform: translate(6px, -0.5rem) scale(1);
    font-size: 0.85rem;
    color: #777;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .form-control:-webkit-autofill ~ .form-label {
    transform: translate(6px, -0.5rem) scale(1);
    font-size: 0.85rem;
    color: #777;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .form-control:hover,
  .select-with-flag:hover {
    border-color: #111;
  }
  .form-control:focus,
  .select-with-flag:focus {
    border-color: $primary;
    box-shadow: 0 0 0 1px $primary;
  }
  .form-control:disabled:hover,
  .select-with-flag:disabled:hover,
  .form-control:disabled:focus,
  .select-with-flag:disabled:focus {
    border-color: #ced4da;
    box-shadow: none;
  }
  .select-with-flag .dropdown-toggle-split::after {
    margin-left: auto;
  }
  .floating-group-input-block {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
  }
  .floating-group-input-block .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;
  }
  &.without-error .input-error-message {
    display: none !important;
  }
  &.rounded-0 > .form-select,
  &.rounded-0 > .form-control {
    border-radius: 0;
    -webkit-border-radius: 0;
  }
}
.input-group-rounded-right-form-control:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  -webkit-border-top-right-radius: .25rem;
  -webkit-border-bottom-right-radius: .25rem;
}

.title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.75rem;
  padding-bottom: 0.75rem;
  margin: 1.5rem 0;
  position: relative;
  font-weight: 500;
}
.title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.25rem;
  width: 3rem;
  background: $primary;
}
.title.title-center::after {
  left: 50%;
  margin-left: -1.5rem;
}
.title-with-icon {
  display: flex;
}
.title-with-icon.title-center {
  justify-content: center;
}
.title-with-icon .title-icon {
  font-size: 2.25rem;
  flex: 0 0 3.25rem;
  max-width: 3.25rem;
}
.title-with-icon::after {
  left: 3.25rem;
}
.section-title {
  font-size: .875rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  flex-wrap: nowrap;
  &::after {
    content: '';
    flex: 1 0;
    width: 100%;
    border-bottom: 1px solid $mutedLight;
    margin-left: .5rem;
  }
  &.no-line::after {
    display: none;
  }
}

.section-title {
  font-size: .875rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  flex-wrap: nowrap;
  &::after {
    content: '';
    flex: 1 0;
    width: 100%;
    border-bottom: 1px solid $mutedLight;
    margin-left: .5rem;
  }
  &.no-line::after {
    display: none;
  }
}

/*Form */
.floating-group textarea.form-control {
  height: auto;
}
.custom-label {
  font-size: 0.85rem;
  color: #777;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.floating-group .form-control:disabled:not(:placeholder-shown) ~ .form-label,
.floating-group .form-control:disabled:focus ~ .form-label {
  background: linear-gradient(0deg, #e9ecef 49%, #fff 50%);
}
.bg-light {
  .floating-group .form-control:not(:placeholder-shown) ~ .form-label,
  .floating-group .form-control:focus ~ .form-label,
  .floating-group .select-with-flag .form-label,
  .floating-group.active .form-label,
  .floating-group .form-label.active {
    background: linear-gradient(0deg, #fff 49%, #f8f9fa 50%);
  }
  .floating-group .form-control:-webkit-autofill ~ .form-label {
    background: linear-gradient(0deg, #fff 49%, #f8f9fa 50%);
  }  
  .floating-group .form-control:disabled:not(:placeholder-shown) ~ .form-label,
  .floating-group .form-control:disabled:focus ~ .form-label {
    background: linear-gradient(0deg, #e9ecef 49%, #f8f9fa 50%);
  }
}
.floating-group .form-control:-webkit-autofill ~ .form-label {
  background: linear-gradient(0deg, #fff 49%, #f8f9fa 50%);
}
.custom-select-lg {
  font-size: 1rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  height: 3rem;
}
.floating-group.phone-group .dropdown .btn {
  width: 3.875rem;
}
.floating-group.phone-group .form-label {
  left: 3.875rem;
}
.btn-uppprcase {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.phoneInput > div > button {
  margin-right: 0 !important;
}

.phoneInput .dropdown-toggle:disabled {
  background-color: #e9ecef;
  opacity: 1;
  padding-right: 0.5625rem;
  &::after {
    display: none;
  }
}

.bg-success-light {
  background-color: #a8ffbb !important;
}
.bg-success-gradient {
  background: #3ec75c;
  background: -moz-linear-gradient( 0deg, #3ec75c, #77c734 );
  background: -webkit-linear-gradient( 0deg, #3ec75c, #77c734 );
  background: linear-gradient( 0deg, #3ec75c, #77c734 );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3ec75c",endColorstr="#77c734",GradientType=1);
}
.bg-danger-gradient {
  background: #dc3545;
  background: -moz-linear-gradient( 0deg, #dc3545, #ff4759 );
  background: -webkit-linear-gradient( 0deg, #dc3545, #ff4759 );
  background: linear-gradient( 0deg, #dc3545, #ff4759 );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dc3545",endColorstr="#ff4759",GradientType=1);
}
.text-light-85 {
  color: rgba(255, 255, 255, 0.85);
}
.text-dark {
  color: $gray-900;
}
.text-dark-25 {
  color: rgba(0, 0, 0, 0.25);
}
.text-muted-light {
  color: $mutedLight;
}
.title-with-icon {
  display: flex;
}
.title-with-icon.title-center {
  justify-content: center;
}
.title-with-icon .title-icon {
  font-size: 2.25rem;
  flex: 0 0 3.25rem;
  max-width: 3.25rem;
}
.title-with-icon::after {
  left: 3.25rem;
}
.child-p-m-0 {
  p {
    margin: 0;
  }
}
.rich-text {
  ul {
    padding-left: 1.25rem;
    li {
      margin-bottom: .5rem;
    }
  }
}

/* Tabs */
.custom-tabs {
  flex-wrap: nowrap;
  .nav-link {
    border-width: 0 0 3px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 0.95rem;
    transition: all 200ms cubic-bezier(0, 0, 1, 0.5) 100ms;
    &:hover {
      border-color: transparent;
      color: #111;
      background: transparent;
    }
    &.active {
      border-color: $primary;
      color: $primary;
      background: transparent;
    }
  }
}

.infinite-scroll-component,
.custom-scroll-bar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    background: linear-gradient(90deg, rgba(255,255,255,0) 45%, #ddd 45%, #ddd 55%, rgba(255,255,255,0) 55%);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background: rgba(230, 13, 73, .65); 
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary; 
  }
}

// Accordion
.accordion-button.active::after {
  transform: var(--#{$prefix}accordion-btn-icon-transform);
}
.accordion-white .accordion-button:not(.collapsed) {
  background-color: $white;
  box-shadow: none !important;
}

@media (min-width: 768px) {
  .text-md-justify {
    text-align: justify;
  }
}


body {
  .rnc__notification-item {
    border-width: 0;
  }
}

@media (max-width: 991px) {
  .custom-tabs {
    overflow-x: auto;
    margin-left: calc(var(--bs-gutter-x) * -.5);
    margin-right: calc(var(--bs-gutter-x) * -.5);
  }
  .custom-tabs .nav-link {
    text-transform: unset;
    letter-spacing: unset;
    white-space: nowrap;
    padding: 0.5rem;
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 1.5rem;
  }
}

@media (max-width: 575px) {
  h3,
  .h3 {
    font-size: 1.25rem;
  }
  h4,
  .h4 {
    font-size: 1.125rem;
  }
  .floating-group .form-control:not(:placeholder-shown) ~ .form-label,
  .floating-group .form-control:focus ~ .form-label,
  .floating-group .select-with-flag .form-label,
  .floating-group .form-label.active {
    letter-spacing: unset;
  }
  body {
    .rnc__notification-container--top-center {
      width: calc(100% - 30px);
      left: 15px;
      .rnc__notification {
        width: 100% !important;
      }
    }
  }
}

// $sizes is dropped from bootstrap 5
// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {
        width: $length !important;
      }
    }
  }
}

.btn-success {
  &, &:disabled, &:hover, &:focus {
    color: $white;
  }
}
.custom-scroll {
  /* Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: $primary $gray;
  }

  /* Chrome, Edge and Safari */
  &::-webkit-scrollbar {
    height: .375rem;
    width: .375rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: .25rem;
    background-color: rgba($black, .1);
  }
  &::-webkit-scrollbar-track:hover {
    background-color: rgba($black, .15);
  }
  &::-webkit-scrollbar-track:active {
    background-color: rgba($black, .15);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: .25rem;
    background-color: rgba($primary, .5);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $primary;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $primary;
  }
}

// Hide Google Captcha Badge
.grecaptcha-badge { visibility: hidden; }
