@import '../../colors';

.cookie-settings-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.cookie-settings-modal {
  background: $white;
  padding: 0 0 1rem;
  width: 55%;
  max-width: 1100px;
  border-radius: 1rem;
  box-shadow: 0 4px 20px $shadow-color;
  max-height: 600px;
  top: 10%;
  bottom: 10%; 
  position: fixed;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;

  .cookie-settings-toggle-group {
    width: 100%;
    .toggle-button {
      width: 100%;
      font-weight: bold;
      padding: 12px;

    }
  }
  .form-check-input {
    transition: 0.5s;
  }


  .accordion-header {
    border-bottom: 1px solid $gray;
    padding: 0.5rem 1rem;
    button {
      width: 100%;
      text-align: left;
      background-color: $white;
      padding-left: 0;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        background-color: $white;
      }
    }
  }

  .accordion-button::before {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1rem;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.5s ease-in-out;
  }
  
  .accordion-button:not(.collapsed)::before {
    transform: rotate(180deg);
  }
  
  .accordion-button::after {
    display: none !important;
  }
  

  .accordion-body {
    padding: 0.5rem 1rem;
    background-color: $white;
    color: $black;
  }

  .accordion-button:not(.collapsed), .accordion-button.collapsed {
    color: $black;
    background-color: $white;
  }

  .cookie-settings-footer {
    display: flex;
    justify-content: space-around;
    padding-top: 1.5rem;
    button {
      padding: 0.625rem 1.5rem;
      font-size: 0.875rem;
      border-radius: 0.25rem;
      &:first-child {
        margin-right: 10px;
      }
      &.btn-reject {
        background-color: $light;
        color: $black;
        border: 1px solid $mutedLight;
      }
      &.btn-accept {
        background-color: $success;
        color: $white;
        border: none;
      }
    }
  }

}
.cookie-settings-body{
    padding: 1rem;
    overflow-y: auto;


    &::-webkit-scrollbar {
        width: 12px; 
      }
    
      &::-webkit-scrollbar-track {
        background: $grayLight; 
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: $gray;
        border-radius: 6px; 
        border: 1px solid $disabled; 
      }
    
      &::-webkit-scrollbar-thumb:hover {
        background-color: $gray; 
      }
    
}


@media (max-width: 1400px) {
    .cookie-settings-modal {
      width: 55%;
      max-height: 75vh;
    }
  }
  

@media (max-width: 1200px) {
  .cookie-settings-modal {
    width: 65%;
    max-height: 75vh;
  }
}

@media (max-width: 768px) {
  .cookie-settings-modal {
    width: 95%;
    margin-bottom: 1rem;
    padding: 0 0 1rem;
    max-height: 650px;

    .cookie-settings-footer {
      flex-direction: column;
      align-items: center;
      padding-top: 0;
      button {
        width: 90%;
        &:first-child {
          margin-top: 0;
        }
        &:not(:first-child) {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
