$primary: #e60d49;
$success: #3ec75c;
$gray: #e9ecef;
$grayLight: #F0F4F6;
$light: #f8f9fa;
$white: #fff;
$black: #000000;
$mutedLight: #666666;
$backdrop-color: rgba(0, 0, 0, 0.6);
$shadow-color: rgba(0, 0, 0, 0.25);
$disabled: #ababab;
$statusInactive: #FF2828;
$statusNotVerified: #6c757d;
$statusBasic: #FDC250;
$statusPlus: #3EC75C;