@import '../../colors';

.cms-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1099;
  background: $white;
  &::before, &::after {
    content: '';
    position: absolute;
    background: #{lighten($primary, 49%)};
    border-radius: 3rem;
  }
  &::before {
    width: 40vw;
    height: 95vh;
    left: -40vw;
    top: 0;
    transform: skew(60deg, -30deg);
  }
  &::after {
    width: 20vw;
    height: 35vh;
    right: -45vh;
    top: 0;
    transform: skew(50deg, -40deg);
  }
  .wrapper {
    height: 100vh;
    overflow: auto;
    position: relative;
    z-index: 10;
  }
  .maintenance-logo {
    width: 260px;
  }
  .maintenance-icon {
    width: 280px;
    height: 280px;
    overflow: hidden;
    margin-left: auto;
    position: relative;
    color: $disabled;
    .spin {
      position: absolute;
    }
    .gear-one {
      font-size: 100px;
      left: 3px;
      top: 90px;
    }
    .gear-two {
      font-size: 140px;
      left: 50px;
      top: 140px;
    }
    .gear-three {
      font-size: 180px;
      left: 112px;
      top: 26px;
    }
    .spin {
      -webkit-animation:spin 4s linear infinite;
      -moz-animation:spin 4s linear infinite;
      animation:spin 4s linear infinite;
    }  
    .spin-back {
      -webkit-animation:spin-back 4s linear infinite;
      -moz-animation:spin-back 4s linear infinite;
      animation:spin-back 4s linear infinite;
    }
  }
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@-moz-keyframes spin-back { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin-back { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin-back { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

@media (max-width: 1199px) {
  .cms-loader {
    &::before {
      width: 65vw;
      left: -100vw;
    }
  }
}
@media (max-width: 767px) {
  .cms-loader {
    &::before {
      left: -130vw;
    }
    &::after {
      width: 35vw;
    }
  }
}