@import 'colors';

$statuses: (
  'inactive': $statusInactive,
  'not-verified': $statusNotVerified,
  'basic': $statusBasic,
  'plus': $statusPlus
);

@each $status, $color in $statuses {
  .status-#{$status}-bg {
    background-color: $color;
  }
  .status-#{$status}-text {
    color: $color;
  }
}