@import '../../colors';

.cookie-consent-modal-backdrop {
  position: fixed;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%; 
  background-color: $backdrop-color; 
  display: flex;
  justify-content: center;
  align-items: flex-end; 
  z-index: 1050;
}

.cookie-consent-modal {
  background: $white; 
  padding: 1rem;
  width: 100%; 
  box-shadow: 0 -5px 15px $shadow-color; 

  .cookie-consent-modal-header {
    .cookie-consent-modal-title {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .cookie-consent-modal-body {
    p {
      margin: 0;
      flex: 1; 
    }

    .cookie-consent-modal-actions {
      display: flex;
      justify-content: end;
    }
  }

  button {
    padding: 0.625rem 1.5rem;
    font-size: 0.875rem;
    margin: 0 10px;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &.btn-accept {
      background-color: $success;
      color: $white;
      border: none;
    }

    &.btn-manage,
    &.btn-reject {
      background-color: $white; 
      color: $black; 
      border: 1px solid $gray; 
    }
  }
}

@media (max-width: 1200px) {
  .cookie-consent-modal-actions {
    width: 100%;
    flex-direction: column; 
    align-items: center;
  }
}

@media (max-width: 768px) {
  .cookie-consent-modal {
    padding: 1rem; 
  }

  .cookie-consent-modal-body {
    flex-direction: column; 
    align-items: flex-start; 

    .cookie-consent-modal-actions {
      width: 100%; 
      flex-direction: column; 
      align-items: stretch; 

      button {
        width: 100%;
        margin: 0px;
        margin-top: 0.5rem; 
      }
    }
  }
}
